/* You can add global styles to this file, and also import other style files */
.page-header.page-header-xs {
  margin-top:73px;
}
.stepform .p-chips{min-height: 45px !important;}
body{font-size: 15px !important; background-color: #fbfcfe !important;}
body .p-inputtext{width: 100%;}
.p-calendar {width: 100%;}

.carousel-inner .carousel-item img {
  border-radius: 1px;
  box-shadow: 0 1px 1px rgb(204 197 185 / 50%);
  height: 300px;
  width: 80%;
}
button.custom-map-control-button {
  height: 35px;
  border: 0;
  box-shadow: 0px 0px 2px 0px #3e3c3c;
  margin-top: 10px;
  border-radius: 2px;
  font-weight: bold;
  font-size: 15px;
}
.carousel-cell{width: 130px !important;text-align: center;font-weight: 500;}
.carousel-cells .carousel-cell img, .carousel-cells .carousel-cell video{width: 80% !important;}

.sidediv{background-color: #eee;padding:30px;border-radius: 15px; box-shadow: 2px 2px 10px #f0eeee;}
.col-md-6 .sidediv{min-height: 700px;}
.font-20{font-size: 20px;} 
.fW-500{font-weight: 500;}
.mt-0{margin-top: 0;}
.warning{color: rgb(231, 105, 8);}
.success{color: rgb(8, 137, 111);}
.iconssave{display: none;}
.textareaDIV:hover + .iconssave {
    display: block;
  }
  .mt-10{margin-top: 10px;}
/* prime ng calendar for signup only */
.signUp .p-calendar{height: 34px;}
.signUp .p-inputtext{
  width: 100%;
  display: block;
  border: none;
  border-bottom: 1px solid #999;
  padding: 6px 25px;
  font-family: Poppins;
  box-sizing: border-box;
  outline: none;
  appearance: unset!important;
  -moz-appearance: unset!important;
  -webkit-appearance: unset!important;
  -o-appearance: unset!important;
  -ms-appearance: unset!important;
}
.signUp .p-button {
  margin: 0;
  color: #010101 !important;
  background-color: #ffffff !important;
  border: 0 !important;
  border-bottom: 1px solid #a6a6a6 !important;
}
.signUp .p-calendar.p-calendar-w-btn .p-datepicker-trigger.p-button {top:0 !important;position: absolute;width: 28px;height: 34px;}

.forgot-modal .ui-dialog .ui-dialog-titlebar {
  background-color: #000 !important;
  color: #fff !important;
  border:0px solid #fff !important;
}

.forgot-modal .ui-dialog {
  box-shadow: 0 0 25px 0 rgb(36 36 37) !important;
  border: 1px solid #959595 !important;
}
.forgot-modal .ui-dialog .ui-dialog-content {
  background-color: #ff8f5e !important;
  color: #fff !important;
  border:0px solid #fff !important;
}
/* prime ng calendar for signup only */

.calendaricon-left .p-calendar.p-calendar-w-btn .p-datepicker-trigger.p-button {left:0 !important}
.calendaricon-left .p-button {
  background-color: #080808 !important;
  border: 0 !important;
}
.calendaricon-left .p-inputtext {padding: 7.8px 0.429em;border: 1px solid #e3dfdf;}

.backlocation{position: fixed;
  color: rgb(255, 250, 250);
  z-index: 999;
  background: rgb(191, 15, 147);
  padding: 10px 5px;
  border-radius: 0 25px 25px 0;
  box-shadow: 3px 3px 20px #000000eb;
  font-size:17px;
  top: 30%;
  border:1px solid rgb(128, 16, 100);
  left: -5px;font-weight: bold;
  width: 30px;
}
.profileUpload .p-dialog .p-dialog-header .p-dialog-title {
  width:100% !important;
}
.backword{display: none;}
  .backlocation a{color:rgb(245, 240, 240);display: flex;}
  .backlocation:hover .backword{display: block;}
  .backlocation:hover{width: 80px;display: flex;}

  /* date picker */

  .ui-widget, .ui-widget * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
body .p-datepicker table th {
  padding: 0.5em;
  font-weight: bold !important;
  background-color: #000!important;
  white-space: nowrap;
  text-align: center !important;
  color: #fff !important;
}

body .p-datepicker table {
  font-size: 14px !important;
  margin: 0.857em 0 0 0 !important;
  font-weight: bold !important;
}
body .p-datepicker .p-datepicker-header .p-datepicker-title {
  margin: 0;
  padding: 0;
  line-height: 1;
  text-align: center;
}
body .p-datepicker table td > a.ui-state-active {
  background-color: #000 !important;
  font-family: Arial, Helvetica, sans-serif;
}
.p-calendar .p-datepicker{min-width: 85% !important;}
body .ui-datepicker table td > a{font-weight: bold;font-family: Arial, Helvetica, sans-serif;}
body .ui-datepicker .ui-datepicker-header .ui-datepicker-title select{border:1px solid #000; border-radius: 4px;}
body .ui-datepicker:not(.ui-state-disabled) .ui-datepicker-header .ui-datepicker-prev:hover, body .ui-datepicker:not(.ui-state-disabled) .ui-datepicker-header .ui-datepicker-next:hover{color: #000 !important;}
.ui-datepicker td{ padding:1px!important; font-size:13px!important;}
.ui-datepicker select.ui-datepicker-month{width: 50% !important;}
body .ui-button {background-color: #000 !important;border:1px solid #000 !important;}
  
  body .ui-dialog .ui-dialog-titlebar{
    background-color: #000 !important;
    color:rgb(243, 239, 239) !important;
    border:1px solid #000 !important;
  }

  .signUp .p-inputtext{padding: 6px 36px;}
  .signUp .p-datepicker:not(.ui-datepicker-inline){top:0 !important;}

  .error{ color:#d65036; font-weight:bold;font-size:12px;padding:5px 1px;}
  .ui-progress-spinner{position: fixed !important;}
  /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.signUpDropdown .p-dropdown {
  border:0px solid #000;
  border-bottom: 1px solid #a6a6a6;
  margin-bottom:19px;
  font-family: inherit;
}
.p-chips{width: 100% !important;}
.loaderdiv{position: absolute;top: 0; width:95%; background-color: #cfccccb0;height: 100%;z-index: 999;}
.objectFit{height: 100%;width:auto;object-fit: contain;}
body .ui-dialog {top:100px !important;}
.commentscroll{min-height:30px !important;max-height: 250px !important;overflow-y: scroll;}
#style-2::-webkit-scrollbar-track
{
	box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #ccc;
}

#style-2::-webkit-scrollbar
{
	width: 7px;
	background-color: #ccc;
}
#style-2::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #878686;
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
  cursor: not-allowed;
}
@media(max-width:767px){
  .remove-footer-mobile{display: none !important;}
  body .ui-dialog{
    margin-top:35px; 
    padding:10px !important;
    left: 0 !important;
    width: 100% !important;
  }
  .col-xs-4{width: 33% !important;}
  .col-xs-8{width: 66% !important;}
  .sidediv{min-height: auto !important;}
  .footer-nav {
    line-height: 8px !important;
  }
  .footer nav>ul>li{padding: 10px 2px !important;line-height: 2 !important;margin: 7px 3px !important;}
  .footer nav > ul a:not(.btn){
    font-size: 11px !important;
  }
  .ui-calendar {
    width: 100% !important;
  }
}

.ng-image-slider .ng-image-slider-container .main .main-inner .img-div img, .ng-image-slider .ng-image-slider-container .main .main-inner .img-div video{
  height: auto !important;
}
.ng-image-slider{height: auto !important;}
.ng-image-slider .ng-image-slider-container .main{height: auto !important;position: relative !important;margin: auto;width: 75% !important;}
.ng-image-slider .ng-image-slider-container .main .main-inner {width: 100% !important;height: auto !important;}
.ng-image-slider .ng-image-slider-container .main .main-inner .img-div.image-popup  {width: 100% !important;height: auto !important;}
.ng-image-slider .ng-image-slider-container .main .main-inner .img-div img{position: relative !important;}
.ng-image-fullscreen-view .lightbox-wrapper .close {
  top: 120px !important;
  right: 50px !important;}
  .p-scrollpanel-wrapper{z-index: auto !important;}
  .form-control:focus-visible{
    text-shadow: none !important;
  }
  .btn:disabled, .btn[disabled], .btn.disabled {
    cursor: not-allowed;
}

.quote {
  color: #8cc540;
  font-style: italic;
}

.redText {
  color: Red;
  font-size: 12px;
  font-family: Arial;
}

h1.titleText {
  color: #69a8cf;
  text-align: left;
  margin-top: 40px;
  font-weight: normal;
}

.postScroll .p-scrollpanel-bar {
  z-index: auto !important;
}

.textDesc h1.titleText{margin-top: 0px !important;}
.textDesc font{font-size: 15px !important;}
body .ui-chips > ul.ui-inputtext .ui-chips-token { margin: 0 0.286em 3px 0 !important;}
.section{background-color: #fbfcfe !important;}
.brandLoader .ui-progress-spinner{position: unset !important;}
.p-dialog .p-dialog-header {
  border-bottom: 1px solid #333333;
  background: #ffffff !important;
  color: #000 !important;
}
.p-dialog .p-dialog-header .p-dialog-header-icon {
  color: #000 !important;
}

.carousel-inner{margin-bottom: 20px;}

.carousel-indicators li {
    background-color: #0a5b1f  !important;
    box-shadow: 1px 2px 3px #b1abab;
}
.travHist .carousel-inner .carousel-item img{height: 275px !important;}
.carousel-indicators .active{background-color: #f5593d !important;}
.carousel-indicators li {
  background-color: #ced4da !important;
  width: 1rem !important;
  height: 1rem !important;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s !important;
  border-radius: 50% !important;}
  .carousel-indicators {
    bottom: -42px !important;
}
@media (min-width: 1200px){
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1400px !important;
  }
}
.p-datepicker table td > span {width: 1.5rem !important; height: 1.5rem !important;}
.p-editor-container .p-editor-toolbar {background: #d3cdcd !important;border:1px solid #ccc !important;}
.zoom-Image .p-dialog{border-radius: 10px;box-shadow: 10px 5px 44px #999393;    min-width: 90%;max-width: 95%; z-index: 1001;}
.zoom-Image .p-dialog .p-dialog-content {background: #000000e6;border-bottom-left-radius:10px;border-bottom-right-radius:10px;padding-top:0;padding-bottom:0;}
.zoom-Image .p-dialog .p-dialog-header {border-top-right-radius: 10px;border-top-left-radius: 10px;background: #fff !important;padding:10px;color: #000 !important;}
.zoom-Image .p-dialog .p-dialog-header .p-dialog-header-icon {color: #c7c5c5 !important;}
.zoom-Image .p-dialog-mask.p-component-overlay {z-index: 9999 !important;}
.card{z-index: auto !important;}
.card-register{z-index: 9999 !important;}
.middle-align{display: flex;position: relative;height: 70vh;justify-content: center;align-items: center;}
.zoom-side-div{background-color: white;color: black;min-height: 100%;max-height: 100%;padding:0 15px;margin-right: -15px;}
.swal2-container{z-index:99999 !important}
.homeSlider .carousel-inner .carousel-item img{height: auto !important;}
.homeSlider .carousel-inner .carousel-item { padding: 2px; background: #c2bfbf61  ; border-radius: 10px  }
.footer-reachus .fa { position: relative; top: 7px }
.ads-card-div.ads-card-div {
  box-shadow: 2px 2px 6px #81808078;
  background-color: rgb(248 219 186 / 26%);
  border-radius: 2px;
  padding: 2px;
  border: 1px solid #ccc;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.travelogueslider .carousel-indicators {
  bottom: -55px !important;
}

.custom-chips .p-chips-container {
  min-height: 50px; /* Adjust this height as needed */
  padding: 5px;
  display: flex;
  align-items: center;
}


.custom-chips .p-chips-token {
  height: 30px; /* Adjust the height of individual chips */
  display: flex;
  align-items: center;
}

.custom-chips .p-inputtext {
  min-height: 50px; /* Match the height of the container */
  padding: 5px;
}

.custom-chips .p-chips-token .p-chips-token-label {
  line-height: 30px; /* Ensure text is vertically centered */
}


.ql-formats .ql-link, .ql-formats .ql-image,  .ql-formats .ql-code-block , .ql-formats .ql-clean{display: none !important;}